<template>
	<div class="h-screen flex w-full bg-img">
		<div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 mx-auto self-center">
			<vx-card>
				<div slot="no-body" class="full-page-bg-color">
					<div class="vx-row">
						<div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
							<img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
						</div>
						<div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center bg-white">
							<div class="p-8">
								<div class="vx-card__title mb-8">
									<h4 class="mb-4">Login</h4>
									<p>Selamat datang silahkan anda login terlebih dahulu.</p>
								</div>
								<vs-input icon="icon icon-user" icon-pack="feather" label-placeholder="Username"
									v-model="username" class="w-full mb-6 no-icon-border"
									:danger="validation.hasError('username')" val-icon-danger="clear"
									:danger-text="validation.firstError('username')" />
								<vs-input type="password" icon="icon icon-lock" icon-pack="feather"
									label-placeholder="Password" v-model="password" class="w-full mb-4 no-icon-border"
									:danger="validation.hasError('password')" val-icon-danger="clear"
									:danger-text="validation.firstError('password')" v-on:keyup.enter='login' />
								<div class="flex flex-wrap items-center justify-between my-5">
									<vs-button @click.prevent="login">Login</vs-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</vx-card>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import SimpleVueValidation from 'simple-vue-validator'
	const Validator = SimpleVueValidation.Validator
	Vue.use(SimpleVueValidation)

	export default {
		data() {
			return {
				username: '',
				password: '',
			}
		},
		validators: {
			username: {
				cache: true,
				debounce: 500,
				validator: function (value) {
					return Validator.value(value).required()
				}
			},
			password: {
				cache: true,
				debounce: 500,
				validator: function (value) {
					return Validator.value(value).required()
				}
			}
		},
		methods: {
			login: function () {
				this.$validate().then(result => {
					if (result) {
						this.$vs.loading()
						let username = this.username
						let password = this.password
						this.$store.dispatch('auth/login', {
								username,
								password
							})
							.then(() => {
								this.$vs.loading.close()
								this.$router.push('/')
							})
							.catch(err => {
								this.$vs.notify({
									title: 'Oops!',
									text: err.response ? err.response.data.message :
										'Terjadi kesalahan silahkan coba lagi, ' + err,
									color: 'danger',
									position: 'top-center'
								})
								this.$vs.loading.close()
							})
					}
				})
			},
		}
	}
</script>